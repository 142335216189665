import React from 'react';
import styles from './Landing.module.css';

import appIcon from '../../assets/images/app-icon.png';
import riderLogo from '../../assets/images/rider-word-logo.png';
import groupScreenshot from '../../assets/images/group-screenshot.png';
import appStoreIcon from '../../assets/images/app-store-icon.png';
import playStoreIcon from '../../assets/images/play-store-icon.png';
import appStoreQrcode from '../../assets/images/app-store-qrcode.png';
import playStoreQrcode from '../../assets/images/play-store-qrcode.png';
import appScreenshot1 from '../../assets/images/app-screenshot-1.png';
import appScreenshot2 from '../../assets/images/app-screenshot-2.png';
import appScreenshot3 from '../../assets/images/app-screenshot-3.png';
import footerImage from '../../assets/images/footer-parkit.png';

export function Landing() {
    return (
        <>
            <div className={styles.lightGreyBanner}>
                <img src={appIcon} className={styles.appIcon} alt="app-icon" />

                <div className={styles.tealBanner}>
                    <img src={riderLogo} className={styles.riderLogo} alt="rider-logo" />
                    <img src={groupScreenshot} className={styles.groupScreenshot} alt="group-screenshot" />
                </div>

                <p className={styles.tealText}>Download Our App Now!</p>
                <div className={styles.downloadRowContainer}>
                    <img src={appStoreIcon} className={styles.downloadIcon} onClick={() => window.open("https://apps.apple.com/my/app/deliverit-rider/id1584329676")} alt="app-store-icon" />
                    {
                        window.innerWidth > 825 &&
                        <>
                            <img src={appStoreQrcode} className={styles.qrcode} alt="app-store-qrcode" />
                            <div className={styles.verticalDivider} />

                        </>
                    }
                    <img src={playStoreIcon} className={styles.downloadIcon} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.deliverit.rider")} alt="play-store-icon" />
                    {
                        window.innerWidth > 825 && <img src={playStoreQrcode} className={styles.qrcode} alt="play-store-qrcode" />
                    }
                </div>
            </div>

            <div className={styles.whiteBanner}>
                <p className={styles.blackText}>This app allows riders to track earnings, performance, cash out, and work information all in one place.</p>
                <div className={styles.appRowContainer}>
                    <div className={styles.appScreenshotContainer}>
                        <img src={appScreenshot1} className={styles.appScreenshot} alt="app-screenshot-1" />
                        <p className={styles.smallBlackText}>Cash out functions</p>
                    </div>
                    <div className={styles.appScreenshotContainer}>
                        <img src={appScreenshot2} className={styles.appScreenshot} alt="app-screenshot-2" />
                        <p className={styles.smallBlackText}>Performance metrics</p>
                    </div>
                    <div className={styles.appScreenshotContainer}>
                        <img src={appScreenshot3} className={styles.appScreenshot} alt="app-screenshot-3" />
                        <p className={styles.smallBlackText}>Daily & weekly earnings statement</p>
                    </div>
                </div>
            </div>

            <div className={styles.darkGreyBanner}>
                <div style={{ display: "flex" }}>
                    <p className={styles.whiteText}>For more information, visit&nbsp;</p>
                    <p className={styles.whiteText} style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open("https://www.deliveritmy.com")}>deliveritmy.com</p>
                </div>
                <img src={footerImage} className={styles.footerImage} onClick={() => window.open("https://www.parkitmy.com")} alt="footer-image" />
            </div>
        </>
    )
}