import './App.css';
import { Landing } from './pages/landing/Landing';

function App() {
  return (
    <Landing />
  );
}

export default App;
